.wrapper {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8;
  @apply overflow-hidden;

  &:global(.numbers-1) {
    @apply grid-cols-1;
  }

  &:global(.numbers-2) {
    @apply grid-cols-1 md:grid-cols-2;
  }
}

.number {
  @apply text-center max-w-1xl mx-auto overflow-hidden;

  & :global(.number-wrapper) {
    @apply flex flex-wrap justify-center items-baseline;
  }

  & span {
    @apply leading-none font-sans font-bold text-green-100;
    @apply text-[8rem] md:text-[16rem];

    &:global(.unit) {
      @apply text-[6rem] md:text-[10rem] ml-2;
    }
  }

  &:global(.has-accent-color span) {
    color: inherit;
  }

  &:global(.has-white-theme span) {
    @apply text-white;
  }

  &:global(.has-black-theme span) {
    @apply text-black-100;
  }

  &:global(.numbers-2) {
    & span {
      @apply text-[10rem];
      &:global(.unit) {
        @apply text-[7rem];
      }
    }
  }

  &:global(.numbers-3) {
    & span {
      @apply text-[6rem];
      &:global(.unit) {
        @apply text-[4rem];
      }
    }
  }

  &:global(.is-wide),
  &:global(.is-wider) {
    & span {
      @apply text-[5rem] md:text-[7rem] lg:text-[9rem];
      &:global(.unit) {
        @apply text-[4rem] md:text-[6rem] lg:text-[7rem] ml-2.5 md:ml-4;
      }
    }
  }

  &:global(.numbers-2),
  &:global(.numbers-3) {
    &:global(.is-wide),
    &:global(.is-wider) {
      & :global(.number-wrapper) {
        @apply flex flex-wrap justify-center items-baseline;
      }
    }

    &:global(.is-wide) {
      & span {
        @apply text-[7rem] md:text-[6rem] lg:text-[7rem];
        &:global(.unit) {
          @apply text-[5rem] md:text-[4rem];
        }
      }
    }

    &:global(.is-wider) {
      & span {
        @apply text-[4rem] lg:text-[5.5rem];
        &:global(.unit) {
          @apply text-[2rem] lg:text-[4rem];
        }
      }
    }
  }

  & :global(.text) {
    @apply text-2xl font-serif pt-1 text-black-100;

    & a {
      @apply underline;
    }
  }
}
